@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
/* @import '../assets/fonts/Termina-Bold.ttf'; */
/* @import '../assets/fonts/Termina-Regular.ttf'; */

@font-face {
    font-family: "Termina";
    src: url("../assets/fonts/Termina-Regular.ttf");
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    scroll-behavior: smooth;
}

html {
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    display: none;
}

.input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

.label,
section>label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: rgba(21, 21, 21, 0.91);
    font-size: 14px;
    font-weight: 200;
}

.button {
    background: #ec5990;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
    display: block;
    appearance: none;
    border-radius: 4px;
    width: 100%;
}

.buttonBlack {
    background: black;
    border: 1px solid white;
}

body {
    margin: 0;
}

.rbc-event-label {
    display: none;
}